<template>
  <div class="readme-article">
    <h2 id="客户群群发">3.客户群群发</h2>
    <h3 id="群发列表">3.1群发列表</h3>
    <p>
      查看群发列表信息，包含群发内容，发送时间，成员发送情况等，可查看群发详情。
    </p>
    <p>
      <img src="@/assets/img/scrm/5-5.png" />
    </p>
    <h3 id="群发编辑">3.2群发编辑</h3>
    <p>
      新建群发，选择群主账号，填写群发内容创建新群发。
    </p>
    <p>
      <img src="@/assets/img/scrm/5-6.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Scrm5-3",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>